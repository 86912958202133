<template>
  <section class="container-fluid nav-col d-flex justify-content-center fixed-top section-1">
    <div class="container">
      <nav class="d-flex justify-content-between navbar navbar-expand-md navbar-dark">
        <!-- Brand -->
        <a class="navbar-brand">
          <img src="@/assets/images/logo.svg" alt="Logo" style="width: 154.19px;height: 39px;flex-shrink: 0;"/>
        </a>
        <!-- Toggler/collapsibe Button -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item" @click="scrollToLocationOfPart('About')">
              <a class="nav-link">About WINC</a>
            </li>
            <li class="nav-item" @click="scrollToLocationOfPart('Features')">
              <a class="nav-link">Features</a>
            </li>
            <li class="nav-item" @click="scrollToLocationOfPart('AI')">
              <a class="nav-link">AI</a>
            </li>
            <li class="nav-item" @click="scrollToLocationOfPart('Roadmap')">
              <a class="nav-link">Roadmap</a>
            </li>
            <li class="nav-item" @click="scrollToLocationOfPart('Multi')">
              <a class="nav-link">Multi-chain</a>
            </li>
            <li class="nav-item" @click="scrollToLocationOfPart('Community')">
              <a class="nav-link">Community</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
  <section class="section-2 container-fluid d-flex justify-content-center" ref="About">
    <div class="container">
      <div class="row d-flex flex-column justify-content-center">
        <div class="section-2-title col-12">About WINC</div>
        <div class="section-2-title-2 col-12" style="margin-top: 48px"><span style="font-weight: 900">WINC</span> –
          Securely Crafted for the
        </div>
        <div class="section-2-title-2 col-12">Crypto Community</div>
        <div class="col-12 d-flex flex-row justify-content-center row align-items-center" style="margin-top: 66px;margin-bottom: 66px">
          <a :href="iosUrl" target="_blank" class="col-12 col-sm-3 mb-3 d-flex section-2-col"><img src="@/assets/images/download_ios.svg" class="down-1 "/></a>
          <a :href="iosUrl " target="_blank" class="col-12 col-sm-3 mb-3 d-flex section-2-col"><img src="@/assets/images/download_android.svg" class="down-2 "/></a>
          <a class="col-12 col-sm-3 mb-3 d-flex section-2-col" :href="androidUrl" target="_blank"><img
              src="@/assets/images/download_androidos.svg" class="down-3 "/></a>
          <!--<a class="col-12 col-sm-3 mb-3 d-flex section-2-col" rel="external" @click="toDown(androidUrl)"><img-->
        </div>
      </div>
    </div>
  </section>
  <section class="section-3 container-fluid">
    <div class="container  d-flex flex-column" style="position: relative">
      <img class="section-3-img" src="@/assets/images/section-3-img.png"/>
      <img class="msg-1" src="@/assets/images/msg1.png"/>
      <img class="msg-2" src="@/assets/images/msg3.png"/>
    </div>
  </section>
  <section class="section-4 container-fluid">
    <div class="container section-4-content" style="position: relative">
      <div class="row flex-column align-items-center">
        <div class="section-2-title col-12">About WINC</div>
        <div class="section-4-des col-12">WINC is a secure messaging platform tailored specifically for the
          cryptocurrency community, blending state-of-the-art encryption with seamless digital currency integration.
          Designed to meet the high security and privacy standards required by crypto enthusiasts and professionals,
          WINC facilitates not just communication, but also the management and exchange of cryptocurrency assets.
        </div>
      </div>
      <img class="section-4-msg-1" src="@/assets/images/msg1.png"/>
      <img class="section-4-msg-2" src="@/assets/images/msg3.png"/>
      <img class="section-4-msg-3" src="@/assets/images/msg2.png"/>
    </div>
  </section>
  <section class="section-5 container-fluid" ref="Features">
    <!--<div id="demo" class="carousel slide container" data-ride="carousel" data-interval="3000">-->
    <div id="demo" class="carousel slide container" data-ride="carousel" data-interval="3000">
      <div class="carousel-inner">
        <!--<div class="carousel-item active">-->
        <!--  <div class="d-flex row justify-content-between align-items-center">-->
        <!--    <div class="d-flex flex-column align-items-start col-xl-7 col-lg-12">-->
        <!--      <div class="section-5-tag">Features</div>-->
        <!--      <div class="section-5-title">Cross-platform Instant Messaging</div>-->
        <!--      <div class="section-5-desc">WINC connects users across various devices and operating systems, ensuring-->
        <!--        that your conversations can flow uninterrupted, whether you're on a phone, tablet, or computer. -->
        <!--      </div>-->
        <!--    </div>-->
        <!--    <div class="col-xl-5 col-lg-12 md-5" style="position: relative">-->
        <!--      <img src="@/assets/images/top-2.svg" class="section-5-img">-->
        <!--      <img src="@/assets/images/top-1.svg" class="section-5-img-1">-->
        <!--    </div>-->
        <!--    &lt;!&ndash;<img class="col-xl-5 col-lg-12 md-5 section-5-img" src="@/assets/images/step-1.svg"/>&ndash;&gt;-->
        <!--  </div>-->
        <!--</div>-->




        <div class="carousel-item active">
          <div class="d-flex row justify-content-between align-items-center" style="width: 100%;height: 100%">
            <div class="d-flex flex-column align-items-start col-xl-7 col-lg-12">
              <div class="section-5-tag">Features</div>
              <div class="section-5-title">Bridge</div>
              <div class="section-5-desc">Seamless cross-chain asset transfer and interoperability solution.</div>
            </div>
            <div class="col-xl-5 col-lg-12 md-5" style="position: relative">
              <img src="@/assets/images/top-2.svg" class="section-5-img">
              <img src="@/assets/images/top-1.svg" class="section-5-img-1">
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="d-flex row justify-content-between align-items-center" style="width: 100%;height: 100%">
            <div class="d-flex flex-column align-items-start col-xl-7 col-lg-12">
              <div class="section-5-tag">Features</div>
              <div class="section-5-title">CEX (Centralized Exchange)</div>
              <div class="section-5-desc">High-speed, low-latency trading platform for quick, efficient trades.</div>
            </div>
            <div class="col-xl-5 col-lg-12 md-5" style="position: relative">
              <img src="@/assets/images/car-2.png" class="car-img-5">
              <!--<img src="@/assets/images/top-2.svg" class="section-5-img">-->
              <!--<img src="@/assets/images/top-1.svg" class="section-5-img-1">-->
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="d-flex row justify-content-between align-items-center" style="width: 100%;height: 100%">
            <div class="d-flex flex-column align-items-start col-xl-7 col-lg-12">
              <div class="section-5-tag">Features</div>
              <div class="section-5-title">DEX (Decentralized Exchange)</div>
              <div class="section-5-desc">Peer-to-peer trading platform without intermediaries or central authorities.
              </div>
            </div>
            <div class="col-xl-5 col-lg-12 md-5" style="position: relative">
              <img src="@/assets/images/car-3.png" class="car-img-5">
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="d-flex row justify-content-between align-items-center" style="width: 100%;height: 100%">
            <div class="d-flex flex-column align-items-start col-xl-7 col-lg-12">
              <div class="section-5-tag">Features</div>
              <div class="section-5-title">Web3 Wallet</div>
              <div class="section-5-desc">Secure, non-custodial wallet for managing multi-chain digital assets.</div>
            </div>
            <div class="col-xl-5 col-lg-12 md-5" style="position: relative;min-height: 260px" >
              <img src="@/assets/images/car-4-img-1.svg" class="car-4-img-1">
              <img src="@/assets/images/car-4-img-2.svg" class="car-4-img-2">
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="d-flex row justify-content-between align-items-center" style="width: 100%;height: 100%">
            <div class="d-flex flex-column align-items-start col-xl-7 col-lg-12">
              <div class="section-5-tag">Features</div>
              <div class="section-5-title">Web3 Livestream</div>
              <div class="section-5-desc">Decentralized, peer-to-peer livestreaming platform built on blockchain
                technology.
              </div>
            </div>
            <div class="col-xl-5 col-lg-12 md-5" style="position: relative">
              <img src="@/assets/images/car-5.png" class="car-img-5">
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#demo" data-slide="prev">
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" href="#demo" data-slide="next" style="left: 90%!important;">
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>
    </div>
  </section>
  <section class="section-6 container-fluid d-flex flex-row justify-content-center" ref="AI">
    <div class="container">
      <div class="row">
        <div class="col-12 section-6-img">
          <img src="@/assets/images/api.png">
        </div>
        <div class="col-12 section-6-title">API</div>
        <div class="col-12 section-6-desc">Leverage our powerful API for integrating various services and automations
          into your applications, enhancing functionality and user experience.
        </div>
      </div>
    </div>
  </section>
  <section class="section-6 container-fluid d-flex flex-row justify-content-center" style="margin-top: 30px">
    <div class="container">
      <div class="row">
        <div class="col-12 section-6-img">
          <img src="@/assets/images/depin.png">
        </div>
        <div class="col-12 section-6-title">DEPIN</div>
        <div class="col-12 section-6-desc">Explore DEPIN, our deep learning platform, which offers innovative solutions
          for data analysis, predictive modeling, and automated decision-making processes.
        </div>
      </div>
    </div>
  </section>
  <section class="section-7 container-fluid d-flex flex-row justify-content-center">
    <div class="container section-7-img d-flex flex-column justify-content-center" ref="Roadmap">
      <div class="section-7-title">Roadmap</div>
      <img src="@/assets/images/roadmap.png" class="section-7-img">
    </div>
  </section>
  <section class="section-8 container-fluid" ref="Multi">
    <div class="section-7-title section-7-title-m">Multi-chain Support</div>
    <div class="container">
      <div class="row flex-row d-flex justify-content-between align-items-center">
        <div class="col-sm-12 col-md-7 d-flex flex-column">
          <div class="section-8-title text-left">Connect Multiple Wallets</div>
          <div class="section-8-desc text-left">Users can link and manage multiple crypto wallets from
            different blockchains within the WINC platform. This integration enables seamless management of assets
            across various ecosystems without needing to switch between different apps or interfaces.
          </div>
        </div>
        <div class="col-sm-12 col-md-5 section-8-img">
          <img src="@/assets/images/multi1.png">
        </div>
      </div>
    </div>
    <div class="container container-2">
      <div class="row flex-row d-flex justify-content-between align-items-center">
        <div class="col-sm-12 col-md-5 section-8-img">
          <img src="@/assets/images/multi2.png">
        </div>
        <div class="col-sm-12 col-md-7 d-flex flex-column">
          <div class="section-8-title text-right">Cross-Chain Transactions</div>
          <div class="section-8-desc text-right">With WINC’s integrated bridge functionalities, users can
            effortlessly perform transactions between different blockchains. This means you can send assets from one
            chain to another quickly and securely, without the need for intermediary exchanges.
          </div>
        </div>
      </div>
    </div>
    <div class="container container-2">
      <div class="row flex-row d-flex justify-content-between align-items-center">
        <div class="col-sm-12 col-md-7 d-flex flex-column">
          <div class="section-8-title text-left">Unified Interface</div>
          <div class="section-8-desc text-left">Despite interacting with multiple blockchains, users
            experience a consistent and intuitive interface on WINC. This simplifies the complexity typically associated
            with blockchain operations, making it accessible even for those new to digital currencies.
          </div>
        </div>
        <div class="col-sm-12 col-md-5 section-8-img">
          <img src="@/assets/images/multi3.png">
        </div>
      </div>
    </div>
  </section>
  <section class="section-9 container-fluid">
    <div class="container d-flex flex-column">
      <div class="section-9-title">Network</div>
      <div class="row  d-flex flex-row  section-9-row">
        <div class="d-flex flex-row section-9-item align-items-center">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="section-9-img">
              <img src="@/assets/images/bitcoin.png">
            </div>
            <div class="section-9-text">Bitcoin</div>
          </div>
          <div class="d-flex flex-column" style="margin-left: 32px">
            <a href="https://bitcoin.org/en/" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Website</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://www.okx.com/web3" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Wallet</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://www.blockchain.com/explorer" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Explorer</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://www.orders.exchange/" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">DEX</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://github.com/bitcoin/bitcoin" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Github</div>
              <div class="section-9-icon"></div>
            </a>
          </div>
        </div>
        <div class="d-flex flex-row section-9-item align-items-center">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="section-9-img">
              <img src="@/assets/images/solana.png">
            </div>
            <div class="section-9-text">Solana</div>
          </div>
          <div class="d-flex flex-column" style="margin-left: 32px">
            <a href="https://solana.com" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Website</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://phantom.app" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Wallet</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://solscan.io" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Explorer</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://raydium.io" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">DEX</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://github.com/solana-labs" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Github</div>
              <div class="section-9-icon"></div>
            </a>
          </div>
        </div>
        <div class="d-flex flex-row section-9-item align-items-center">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="section-9-img">
              <img src="@/assets/images/tron.png">
            </div>
            <div class="section-9-text">Tron</div>
          </div>
          <div class="d-flex flex-column" style="margin-left: 32px">
            <a href="https://tron.network/" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Website</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://www.tronlink.org/" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Wallet</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://tronscan.org/#/" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Explorer</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://sunswap.com/#/home" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">DEX</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://github.com/tronprotocol" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Github</div>
              <div class="section-9-icon"></div>
            </a>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center section-9-item">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="section-9-img">
              <img src="@/assets/images/ethereum.png">
            </div>
            <div class="section-9-text">Ethereum</div>
          </div>
          <div class="d-flex flex-column" style="margin-left: 32px">
            <a href="https://ethereum.org/en/" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Website</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://metamask.io/" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Wallet</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://etherscan.io/" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Explorer</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://uniswap.org/" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">DEX</div>
              <div class="section-9-icon"></div>
            </a>
            <a href="https://github.com/ethereum" target="_blank"
               class="d-flex flex-row justify-content-between align-items-center">
              <div class="section-9-link">Github</div>
              <div class="section-9-icon"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-10 container-fluid" ref="Community">
    <div class="container">
      <div class="section-10-title">Community</div>
      <div class="section-10-desc">Join our vibrant community to stay updated and engage with other users</div>
      <div class="section-10-content row flex-row justify-content-between">
        <div class="section-10-item col-5 col-sm-5 col-xl-5">
          <div class="section-10-icon">
            <img src="@/assets/images/twitter.png">
          </div>
          <div class="section-10-text">X(Twitter)</div>
          <img src="@/assets/images/x.svg" class="section-10-bg-1">
        </div>
        <a class="section-10-item col-5 col-sm-5 col-xl-5" target="_blank" href="https://t.me/WINC_AI_Official">
          <div class="section-10-icon">
            <img src="@/assets/images/telegram.png">
          </div>
          <div class="section-10-text">Telegram</div>
          <img src="@/assets/images/t.svg" class="section-10-bg-2">
        </a>
        <div class="section-10-item col-5 col-sm-5 col-xl-5">
          <div class="section-10-icon">
            <img src="@/assets/images/medium.png">
          </div>
          <div class="section-10-text">Medium</div>
          <img src="@/assets/images/m.svg" class="section-10-bg-3">
        </div>
        <div class="section-10-item col-5 col-sm-5 col-xl-5">
          <div class="section-10-icon">
            <img src="@/assets/images/youtube.png">
          </div>
          <div class="section-10-text">Youtube</div>
          <img src="@/assets/images/y.svg" class="section-10-bg-4">
        </div>
      </div>
    </div>
  </section>
  <section class="section-11 container-fluid">
    <div class="container d-flex flex-column">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="section-11-logo"></div>
        <div class="other-row">
          <div class="other-col"></div>
          <div class="other-col"></div>
          <div class="other-col"></div>
          <div class="other-col"></div>
        </div>
      </div>
      <div class="section-11-link-row row d-flex flex-row justify-content-between">
        <div class="col-xl-3 col-md-6 col-6 mb-4 section-11-link-col d-flex flex-column align-items-sm-start">
          <div class="section-11-link-title">What we do</div>
          <div class="section-11-link-text" @click="scrollToLocationOfPart('About')">About WINC</div>
          <div class="section-11-link-text">Blog</div>
          <div class="section-11-link-text" @click="scrollToLocationOfPart('Roadmap')">Roadmap</div>
        </div>
        <div class="col-xl-3 col-md-6 col-6 mb-4 section-11-link-col d-flex flex-column align-items-sm-start">
          <div class="section-11-link-title">Use WINC</div>
          <a :href="iosUrl" target="_blank">
            <div class="section-11-link-text">Android</div>
          </a>
          <a :href="iosUrl" target="_blank">
            <div class="section-11-link-text">iPhone</div>
          </a>
          <a href="http://wchy.pinpaijian.com/app/pc/WinCloud.exe" target="_blank">
            <div class="section-11-link-text">MAC/PC</div>
          </a>
          <!--<div class="section-11-link-text">WINC Web</div>-->
        </div>
        <div class="col-xl-3 col-md-6 col-6 mb-4 section-11-link-col d-flex flex-column align-items-sm-start">
          <div class="section-11-link-title">Need help?</div>
          <div class="section-11-link-text">FAQ</div>
          <div class="section-11-link-text">24h Service</div>
        </div>
        <div class="col-xl-3 col-md-6 col-6 mb-4 section-11-down d-flex flex-column align-items-sm-start">
          <a :href="iosUrl" target="_blank"><img src="@/assets/images/download_ios.svg" class="section-11-down-1"/></a>
          <a :href="iosUrl" target="_blank"><img src="@/assets/images/download_android.svg" class="section-11-down-2"/></a>
          <a :href="androidUrl" target="_blank"><img
              src="@/assets/images/download_androidos.svg" class="section-11-down-3 "/></a>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios"

export default {
  name: 'HomeView',
  data() {
    return {
      androidUrl: '',
      iosUrl: ''
    }
  },
  created() {
    axios({
      method: "get",
      url: "https://wincloud-app.oss-accelerate.aliyuncs.com/app_address.json",
    }).then(res => {
      const data = res.data.data.list[0];
      this.androidUrl = data.androidAppUrl
      this.iosUrl = data.iosAppUrl
    }).catch(error => {

    }).finally(() => {
      return axios({ method: "get", url: '/fulidownload/api/mdm/link/nuc48e5t' })
    }).then((res) => {
    }).catch((error) => {
      console.log(error)
    })

  },
  methods: {
    scrollToLocationOfPart(partIndex) {
      const el = this.$refs[partIndex]
      if (el) {
        window.scrollTo({
          top: el.offsetTop,
          behavior: 'smooth'
        })
      }
    },
    toDown(url){
      window.location.href = url;
    }
  }
}
</script>
<style scoped>
@font-face {
  font-family: Roboto;
  font-weight: 500;
  src: url("@/assets/font/Roboto-Medium.ttf")
}
@font-face {
  font-family: Roboto;
  font-weight: 700;
  src: url("@/assets/font/Roboto-Bold.ttf")
}
@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url("@/assets/font/Montserrat-Regular.otf")
}
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url("@/assets/font/Montserrat-SemiBold.otf")
}
@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url("@/assets/font/Montserrat-Bold.otf")
}
@font-face {
  font-family: Montserrat;
  font-weight: 900;
  src: url("@/assets/font/Montserrat-ExtraBold.otf")
}
@font-face {
  font-family: Plus Jakarta Sans;
  font-weight: 900;
  src: url("@/assets/font/PlusJakartaText-Bold.otf")
}
@font-face {
  font-family: Plus Jakarta Sans;
  font-weight: 600;
  src: url("@/assets/font/PlusJakartaDisplay-Medium.otf")
}
@font-face {
  font-family: Plus Jakarta Sans;
  font-weight: 400;
  src: url("@/assets/font/PlusJakartaText-Italic.otf")
}

.section-1 {
  border-bottom: 1px solid #DDE1E6;
  background-color: #fff;
}
.nav-link {
  color: #222E3F !important;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.section-2 {
  margin-top: 192px;
}
.section-2-title {
  color: #191E25;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 84.48px;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  line-height: 110%; /* 57.2px */
}
.section-2-title-2 {
  color: #0063FF;
  font-family: Montserrat;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: 110%;
}
.section-2-col{

}
.down-1 {
  width: 120px;
  height: 40px;
  margin-right: 20px;
  margin: auto;
}
.down-2 {
  width: 135px;
  height: 40px;
  margin-right: 20px;
  margin: auto;
}
.down-3 {
  width: 135px;
  height: 40px;
  margin: auto;
}
.section-3-img {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
  flex-shrink: 0;
 }
.msg-1 {
  width: 414.89px;
  height: 110px;
  position: absolute;
  top: -30px;
  right: 200px;
}
.msg-2 {
  width: 360px;
  height: 49px;
  position: absolute;
  top: 166px;
  right: 74px;
}
.section-4 {
  background-color: #E1EFFF;
}
.section-4-content {
  padding: 80px 80px 170px;
}
.section-4-des {
  margin-top: 80px;
  color: #191E25;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 140%; /* 33.6px */
}
.section-4-msg-1 {
  width: 414.89px;
  height: 110px;
  position: absolute;
  top: 80px;
  left: -20px;
}
.section-4-msg-2 {
  width: 360px;
  height: 49px;
  position: absolute;
  bottom: 119px;
  right: 0px;
}
.section-4-msg-3 {
  width: 360px;
  height: 76px;
  position: absolute;
  bottom: 74px;
  left: 120px;
}
.section-5 {
  padding: 134px 0;
}
.section-5-tag {
  text-align: left;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 35.2px */
  background: linear-gradient(196deg, #00A2FF 11.19%, #0063FF 88.81%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section-5-title {
  color: #21272A;
  text-align: left;
  /* Heading/2 */
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 46.2px */
  margin-top: 10px;
}
.section-5-desc {
  color: #21272A;
  /* Body/L */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
  margin-top: 24px;
}
.section-5-img {
  width: 84%;
  height: auto;
  border-radius: 8px;
  border: 1px solid #f3f4f5;
  /*box-shadow: 10px 10px 5px 10px #f3f4f5;*/
}
.section-5-img-1{
  border-radius: 8px;
  border: 1px solid #f3f4f5;
  position: absolute;
  width: 50%;
  height: auto;
  top: 210px;
  left: 213px;
}
.car-4-img-1{
  width: 185px;
  height: 153px;
  position: absolute;
  top: 0px;
  left: 0px;
}
.car-4-img-2{
  width: 185px;
  height: 197px;
  position: absolute;
  top: 50px;
  left: 60px;
}
.car-img-5{
  width: 100%;
  height: auto;
}
.section-6 {

}
.section-6-img {
  width: 971.52px;
  height: auto;
}
.section-6-img img {
  width: 100%;
  height: auto;
  margin: auto;
  border-radius: 24px;
  overflow: hidden;
}
.section-6-title {
  margin-top: 38px;
  color: #14183E;
  font-family: "Plus Jakarta Sans";
  font-size: 84.48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.section-6-desc {
  margin-top: 55px;
  color: #191E25;
  text-align: center;
  font-family: Montserrat;
  font-size: 46.08px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 64.512px */
  text-align: center;
}
.section-7 {

}
.section-7-img {
  width: 100%;
}
.section-7-title {
  margin-top: 130px;
  color: #14183E;
  font-family: "Plus Jakarta Sans";
  font-size: 84.48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.section-7-img img {
  width: 100%;
  height: auto;
}
.section-8 {
  padding-top: 140px;
}
.section-8-title {
  color: #14183E;
  font-family: "Plus Jakarta Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.section-8-desc {
  color: #191E25;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  margin-top: 71px;
}
.section-8-img {
  width: 100%;
}
.section-8-img img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  margin: auto;
}
.section-9 {
  margin-top: 93px;
}
.section-9-title {
  color: #14183E;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 53.894px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.section-9-item {
  border-radius: 8.268px;
  background: #FFF;
  box-shadow: 0px 4.134px 8.268px 0px rgba(0, 0, 0, 0.25);
  padding: 18px;
  margin-top: 94px;
}
.section-9-img {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}
.section-9-text {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16.537px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16.537px */
  margin-top: 24px;
  text-align: center;
}
.section-9-link {
  color: #767676;
  text-align: center;
  font-family: Montserrat;
  font-size: 16.537px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 23.152px */
  text-decoration-line: underline;
  text-align: left;
  width: 80px;
}
.section-9-icon {
  width: 16.537px;
  height: 16.537px;
  flex-shrink: 0;
  margin-left: auto;
  background-image: url("@/assets/images/jump.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.section-10 {
  padding-top: 120px;
  padding-bottom: 100px;
}
.section-10-title {
  color: #14183E;
  font-family: "Plus Jakarta Sans";
  font-size: 84.48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: left;
}
.section-10-desc {
  margin-top: 52px;
  color: #191E25;
  font-family: Montserrat;
  font-size: 46.08px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 64.512px */
  text-align: left;
}
.section-10-content {
  margin-top: 50px;
}
.section-10-item {
  border-radius: 46.08px;
  background: #F8FBFF;
  box-shadow: 0px 7.68px 15.36px 0px rgba(0, 0, 0, 0.25);
  padding: 35px 35px;
  margin-bottom: 50px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}
.section-10-bg-1 {
  position: absolute;
  width: 218.944px;
  height: 218.944px;
  top: 0;
  right: 0;
}
.section-10-bg-2 {
  position: absolute;
  width: 252px;
  height: 252px;
  flex-shrink: 0;
  top: 0;
  right: -15px;
}
.section-10-bg-3 {
  position: absolute;
  width: 200.828px;
  height: 200.828px;
  flex-shrink: 0;
  opacity: 0.3;
  top: 15px;
  right: -15px;
}
.section-10-bg-4 {
  position: absolute;
  width: 253.75px;
  height: 184.025px;
  flex-shrink: 0;
  opacity: 0.2;
  top: 25px;
  right: -30px;
}


.section-10-icon {
  display: flex;
  width: 80.14px;
  height: 80.14px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #ECF5FF;
}
.section-10-icon img {
  width: 46.8px;
  height: 46.8px;
  flex-shrink: 0;
  margin: auto;
}
.section-10-text {
  color: #0063FF;
  font-family: "Plus Jakarta Sans";
  font-size: 30.44px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 40px;
  text-decoration: none;
}
.section-11 {
  background: #333;
  padding: 27px 39px;
}
.section-11-logo {
  width: 154px;
  height: 40px;
  flex-shrink: 0;
  background-image: url("@/assets/images/logo_h.png");
}
.other-row {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}
.other-col {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.other-row > .other-col:nth-child(1) {
  background-image: url('@/assets/images/twitter_white.png');
}
.other-row > .other-col:nth-child(2) {
  background-image: url('@/assets/images/telegram_white.png');
}
.other-row > .other-col:nth-child(3) {
  background-image: url('@/assets/images/medium_white.png');
}
.other-row > .other-col:nth-child(4) {
  background-image: url('@/assets/images/youtube_white.png');
}
.section-11-link-row {
  margin-top: 27px;

}
.section-11-link-col {
  width: 140px;
}
.section-11-link-title {
  color: #FFF;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  margin-bottom: 12px;
  text-align: left;
}
.section-11-link-text {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-top: 16px;
  text-align: left;
}
.section-11-down {
  width: 169px;
}
.section-11-down-1 {
  width: 120px;
  height: auto;
  margin-bottom: 16px;
}
.section-11-down-2 {
  width: 135px;
  height: auto;
  margin-bottom: 16px;
}
.section-11-down-3 {
  width: 135px;
  height: auto;
}
.container-2{
  margin-top: 120px;
}
.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.section-9-row{
  justify-content: space-between;
}
.section-7-title-m{
  margin-top: 0;
  margin-bottom: 120px
}
@media screen and (max-width: 500px) {
  .section-2-title {
    font-size: 2rem;
  }
  .section-2-title-2{
    font-size: 1.5rem;
  }
  .msg-1{
    width: 119px;
    height: auto;
    position: absolute;
    top: 20px;
    right: 30px;
  }
  .msg-2{
    width: 119px;
    height: auto;
    position: absolute;
    top: 60px;
    right: 30px
  }
  .section-4-content{
    padding: 30px 0;
  }
  .section-4-des{
    font-size: 1rem;
    margin-top: 30px;
  }
  .section-4-msg-1 {
    display: none;
  }
  .section-4-msg-2{
    display: none;
  }
  .section-4-msg-3{
    display: none;
  }
  .section-5 {
    padding: 60px 0;
  }
  .section-5-tag{
    font-size: 2rem;
  }
  .section-5-title{
    font-size: 2rem;
  }
  .section-5-desc{
    font-size: 1rem;
  }
  .section-6-title{
    font-size: 2rem;
  }
  .section-6-desc{
    font-size: 1.2rem;
    margin-top: 25px;
  }
  .section-7{
    padding-left: 0;
    padding-right: 0;
    padding-top: 84px;
  }
  .section-7-title{
    font-size: 2rem;
    margin-top: 0;
  }

  .section-7-img{
    width: 100%;
    height: auto;
  }
  .section-8{
    padding-top: 40px;
  }
  .section-7-title{
    font-size: 2rem;
  }
  .section-7-title-m{
    margin-top: 0;
    margin-bottom: 30px
  }
  .section-8-title{
    font-size: 1.5rem;
  }
  .section-8-desc{
    font-size: 1.125rem;
    margin-top: 35px;
  }
  .section-8-img{
    width: 100%;
    height: auto;
  }
  .container-2{
    margin-top:40px;
  }
  .section-9{
    margin-top: 40px;
  }
  .section-9-title{
    font-size: 2rem;
  }
  .section-9-item{
    margin-top: 30px;
  }
  .section-9-row{
    justify-content: center;
  }
  .section-10-title{
    font-size: 2rem;
  }
  .section-10-desc{
    font-size: 1.2rem;
  }
  .section-10{
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .section-11{
    padding: 30px 20px;
  }
  .section-11-link-title{
    font-size: 1.2rem;
  }
  .section-11-link-text{
    font-size: 0.8rem;
  }
  .section-10-item{
    padding: 18px 13px;
    border-radius: 12px;
  }
  .section-10-icon{
    width: 42px;
    height: 42px;
    flex-shrink: 0;
  }
  .section-10-icon img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  .section-10-text{
    font-size: 1.2rem;
    margin-top: 18px;
  }
  .section-10-bg-1{
    display: none;
  }
  .section-10-bg-2{
    display: none;
  }
  .section-10-bg-3{
    display: none;
  }
  .section-10-bg-4{
    display: none;
  }
  .section-10{
    padding-left: 30px;
    padding-right: 30px;
  }
  .section-5-img-1{
    width: 47%;
    top: 175px;
    left: 183px;
  }
  .section-5-img {
    width: 82%;
    margin-top: 20px;
  }
}
.navbar-toggler{
  background-color: #0063FF!important;
}
</style>
